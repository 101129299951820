import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  equipe: [],
  anexo: [],
  anexoRisco: [],
  anexoAnalise: [],
  anexoPA: []
}

export const gmSlice = createSlice({
  name: "gm",
  initialState,
  reducers: {
    addEquipe: (state, action) => {

      state.equipe.push(action.payload)

      // mutates the state
      // return {
      //   ...state,
      //   equipe: action.payload
      // }
    },
    setNewEquipe: (state, action) => {
      // state.equipe.push(action.payload)

      // mutates the state
      return {
        ...state,
        equipe: action.payload
      }
    },
    resetEquipe: (state) => {
      return {
        ...state,
        equipe: []
      }
    },
    addAnexo: (state, action) => {
      // const a = action.payload

      // console.log(a)
      // console.log(typeof a)

      state.anexo.push(action.payload);
      // console.log(state.anexo)
      return state

      // mutates the state
      // return {
      //   ...state,
      //   equipe: action.payload
      // }
    },
    setNewAnexo: (state, action) => {
      // state.equipe.push(action.payload)
      console.log(action.payload)
      // mutates the state
      return {
        ...state,
        anexo: action.payload
      }
    },
    resetAnexo: (state) => {
      return {
        ...state,
        anexo: []
      }
    },
    addAnexoRisco: (state, action) => {
      state.anexoRisco.push(action.payload);
      
      return state

      // mutates the state
      // return {
      //   ...state,
      //   equipe: action.payload
      // }
    },
    setNewAnexoRisco: (state, action) => {
      // state.equipe.push(action.payload)

      // mutates the state
      return {
        ...state,
        anexoRisco: action.payload
      }
    },
    resetAnexoRisco: (state) => {
      return {
        ...state,
        anexoRisco: []
      }
    },
    addAnexoAnalise: (state, action) => {
      // const a = action.payload

      // console.log(a)
      // console.log(typeof a)

      state.anexoAnalise.push(action.payload);
      // console.log(state.anexo)
      return state

      // mutates the state
      // return {
      //   ...state,
      //   equipe: action.payload
      // }
    },
    setNewAnexoAnalise: (state, action) => {
      // state.equipe.push(action.payload)
      console.log(action.payload)
      // mutates the state
      return {
        ...state,
        anexoAnalise: action.payload
      }
    },
    resetAnexoAnalise: (state) => {
      return {
        ...state,
        anexoAnalise: []
      }
    },
    addAnexoPA: (state, action) => {
      // const a = action.payload

      // console.log(a)
      // console.log(typeof a)

      state.anexoPA.push(action.payload);
      // console.log(state.anexo)
      return state

      // mutates the state
      // return {
      //   ...state,
      //   equipe: action.payload
      // }
    },
    setNewAnexoPA: (state, action) => {
      // state.equipe.push(action.payload)
      console.log(action.payload)
      // mutates the state
      return {
        ...state,
        anexoPA: action.payload
      }
    },
    resetAnexoPA: (state) => {
      return {
        ...state,
        anexoPA: []
      }
    }
  }
})

export const selectEquipe = (state) => state.gm.equipe;

export const selectAnexo = (state) => state.gm.anexo;

export const selectAnexoRisco = (state) => state.gm.anexoRisco;

export const selectAnexoAnalise = (state) => state.gm.anexoAnalise;

export const selectAnexoPA = (state) => state.gm.anexoPA;

export const { addEquipe, setNewEquipe, resetEquipe, addAnexo, setNewAnexo, resetAnexo, addAnexoRisco, setNewAnexoRisco, resetAnexoRisco, addAnexoAnalise, setNewAnexoAnalise, resetAnexoAnalise, addAnexoPA, setNewAnexoPA, resetAnexoPA } = gmSlice.actions

export default gmSlice.reducer